<template>
  <div>
    <div class="d-flex justify-content-start mb-5">
      <button
        @click="
          exportExcel(
            'exportTransmittal',
            `${transmittalDetail.number} - ${transmittalDetail.project.nickname} - Transmittal`
          )
        "
        class="btn btn-success btn-md mr-2"
      >
        <i class="fa fa-file-excel" /> Excel
      </button>

      <button class="btn btn-danger btn-md mr-2" @click="exportPDF">
        <i class="fa fa-file-pdf" /> PDF
      </button>
    </div>

    <div>
      <div class="card card-custom p-5 gutter-b">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Transmittal Number</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Transmittal Number"
                v-model="transmittalDetail.number"
                disabled
              ></b-form-input>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Company</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Company"
                v-model="transmittalDetail.company.name"
                disabled
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-custom p-5 gutter-b">
        <div class="card-body p-0">
          <h3>Purpose</h3>
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center table-head-bg table-bordered"
            >
              <template>
                <tbody>
                  <tr>
                    <td style="width: 5em;">
                      <div>
                        <b-form-checkbox
                          id="checkbox-approval"
                          v-model="transmittalDetail.review_approval"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                        </b-form-checkbox>
                      </div>
                    </td>
                    <td>Review & Approval</td>
                  </tr>
                  <tr>
                    <td style="width: 5em;">
                      <div>
                        <b-form-checkbox
                          id="checkbox-stamps"
                          v-model="transmittalDetail.stamp_signature"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                        </b-form-checkbox>
                      </div>
                    </td>
                    <td>Stample & Signature</td>
                  </tr>
                  <tr>
                    <td style="width: 5em;">
                      <div>
                        <b-form-checkbox
                          id="checkbox-information"
                          v-model="transmittalDetail.information"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                        </b-form-checkbox>
                      </div>
                    </td>
                    <td>Information</td>
                  </tr>
                  <tr>
                    <td style="width: 5em;">
                      <div>
                        <b-form-checkbox
                          id="checkbox-installation"
                          v-model="transmittalDetail.information_installation"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                        </b-form-checkbox>
                      </div>
                    </td>
                    <td>Construction & Installation</td>
                  </tr>
                  <tr>
                    <td style="width: 5em;">
                      <div>
                        <b-form-checkbox
                          id="checkbox-copy"
                          v-model="transmittalDetail.copies"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                        </b-form-checkbox>
                      </div>
                    </td>
                    <td>Copies</td>
                  </tr>
                </tbody>
              </template>
            </table>
          </div>
        </div>
      </div>

      <div class="card card-custom p-5 gutter-b">
        <div class="card-body p-0">
          <h3>Status</h3>
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center table-head-bg table-bordered"
            >
              <template>
                <tbody>
                  <tr>
                    <td style="width: 5em;">
                      <div>
                        <b-form-checkbox
                          id="checkbox-preliminary"
                          v-model="transmittalDetail.preliminary"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                        </b-form-checkbox>
                      </div>
                    </td>
                    <td>Preliminary</td>
                  </tr>
                  <tr>
                    <td style="width: 5em;">
                      <div>
                        <b-form-checkbox
                          id="checkbox-revision"
                          v-model="transmittalDetail.revision"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                        </b-form-checkbox>
                      </div>
                    </td>
                    <td>Revision</td>
                  </tr>
                  <tr>
                    <td style="width: 5em;">
                      <div>
                        <b-form-checkbox
                          id="checkbox-final"
                          v-model="transmittalDetail.final"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                        </b-form-checkbox>
                      </div>
                    </td>
                    <td>Final</td>
                  </tr>
                  <tr>
                    <td style="width: 5em;">
                      <div>
                        <b-form-checkbox
                          id="checkbox-withdrawal"
                          v-model="transmittalDetail.withdrawal"
                          value="1"
                          unchecked-value="0"
                          disabled
                        >
                        </b-form-checkbox>
                      </div>
                    </td>
                    <td>Withdrawal</td>
                  </tr>
                </tbody>
              </template>
            </table>
          </div>
        </div>
      </div>

      <div class="card card-custom p-5 gutter-b">
        <div class="card-body p-0">
          <h3>Design</h3>
          <div class="table-responsive">
            <table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
              <thead>
                <tr>
                  <th>Status</th>
                  <th style="width: 25em;">Numbering</th>
                  <th style="width: 5em;">Revisi</th>
                  <th>Title</th>
                </tr>
              </thead>
              <template v-for="(item, i) in transmittalDetail.design">
                <tbody v-bind:key="i">
                  <tr>
                    <td>
                      {{
                        item.design
                          ? item.design.last_status
                            ? item.design.last_status.name
                            : ""
                          : ""
                      }}
                    </td>
                    <td>{{ item.design ? item.design.numbering : "" }}</td>
                    <td>{{ item.design ? item.design.revision : "" }}</td>
                    <td>{{ item.design ? item.design.title : "" }}</td>
                  </tr>
                </tbody>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div hidden>
      <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a3"
        pdf-orientation="landscape"
        pdf-content-width="1500px"
        ref="html2Pdf"
        :html-to-pdf-options="{
          filename: `${transmittalDetail.number} - ${transmittalDetail.project.nickname} - Transmittal`,
          margin: 5,
          jsPDF: {
            unit: 'mm',
            format: 'a3',
            orientation: 'landscape',
          },
        }"
      >
        <section slot="pdf-content">
          <div id="exportTransmittal" ref="exportTransmittal">
            <div class="p-0 ma-0">
              <table style="width: 100% !important;">
                <tr>
                  <td rowspan="2" width="100">
                    <img
                      src="media/logos/len-railway-logo.png"
                      alt="Logo"
                      width="7%"
                      class="logo"
                    />
                  </td>
                  <td>
                    <h2>
                      PT. Len Railway System
                    </h2>
                    <p>
                      Jl. Soekarno Hatta <br />
                      Bandung - 40254, Indonesia
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <div
                style="font-size: 20px;color: black;font-weight: bold; text-align: center"
              >
                <hr style="background-color: black;" />
                TRANSMITTAL SHEET
                <hr style="background-color: black;" />
              </div>
            </div>

            <table>
              <tr>
                <td colspan="1">
                  To
                </td>
                <td>:</td>
                <td colspan="2">
                  {{ transmittalDetail.company.name }}
                </td>
                <td></td>
                <td style="font-weight: bold;" colspan="1">
                  Project Name
                </td>
                <td>:</td>
                <td colspan="3">
                  {{ transmittalDetail.project.name }}
                </td>
              </tr>

              <tr>
                <td colspan="1">Attn</td>
                <td>:</td>
                <td colspan="2">{{ transmittalDetail.attention }}</td>
                <td></td>
                <td colspan="1">
                  Project Code
                </td>
                <td>:</td>
                <td colspan="3">{{ transmittalDetail.project.code }}</td>
              </tr>

              <tr>
                <td colspan="1">Subject</td>
                <td>:</td>
                <td colspan="2">{{ transmittalDetail.subject }}</td>
                <td></td>
                <td colspan="1">Date</td>
                <td>:</td>
                <td colspan="1">{{ transmittalDetail.date }}</td>
              </tr>

              <tr>
                <td colspan="1">Transmittal</td>
                <td>:</td>
                <td colspan="2">{{ transmittalDetail.number }}</td>
                <td></td>
                <td colspan="1">Page</td>
                <td>:</td>
                <td colspan="3">1 of 1</td>
              </tr>

              <div>
                <div
                  style="font-size: 20px;color: black;font-weight: bold; text-align: center"
                >
                  <hr style="background-color: black;" />
                </div>
              </div>
            </table>

            <div>
              <div
                style="font-size: 20px;color: black;font-weight: bold; text-align: center"
              >
                <hr style="background-color: black;" />
              </div>
            </div>

            <table>
              <tr>
                <td colspan="2">The following are transmitted here with for</td>
                <td colspan="1">
                  (<b>{{ transmittalDetail.review_approval ? "V" : "X" }}</b
                  >)
                </td>
                <td colspan="2">Review & Approval</td>
                <td colspan="1">
                  (<b>{{ transmittalDetail.information ? "V" : "X" }}</b
                  >)
                </td>
                <td colspan="2">Information</td>
                <td colspan="1">
                  (<b>{{ transmittalDetail.copies ? "V" : "X" }}</b
                  >)
                </td>
                <td colspan="1">Copies</td>
              </tr>

              <tr>
                <td colspan="2"></td>
                <td colspan="1">
                  (<b>{{ transmittalDetail.stamp_signature ? "V" : "X" }}</b
                  >)
                </td>
                <td colspan="2">Stamps & Signature</td>
                <td colspan="1">
                  (<b>{{
                    transmittalDetail.information_installation ? "V" : "X"
                  }}</b
                  >)
                </td>
                <td colspan="2">Construction & Installation</td>
                <td colspan="1">
                  (<b>{{ transmittalDetail.copies ? "V" : "X" }}</b
                  >)
                </td>
              </tr>

              <tr>
                <td colspan="2">These document are :</td>
                <td colspan="1">
                  (<b>{{ transmittalDetail.preliminary ? "V" : "X" }}</b
                  >)
                </td>
                <td colspan="2">Preliminary</td>
                <td colspan="1">
                  (<b>{{ transmittalDetail.revision ? "V" : "X" }}</b
                  >)
                </td>
                <td colspan="2">Revision</td>
                <td colspan="1">
                  (<b>{{ transmittalDetail.final ? "V" : "X" }}</b
                  >)
                </td>
                <td colspan="1">Final</td>
              </tr>

              <tr>
                <td colspan="2"></td>
                <td colspan="1">
                  (<b>{{ transmittalDetail.withdrawal ? "V" : "X" }}</b
                  >)
                </td>
                <td colspan="2">Withdrawal</td>
              </tr>
            </table>

            <div>
              <div
                style="font-size: 20px;color: black;font-weight: bold; text-align: center"
              >
                <hr style="background-color: black;" />
              </div>
            </div>

            <table class="table" style="width:100%">
              <tr>
                <td style="font-weight: bold !important;">NO.</td>
                <td style="font-weight: bold !important;">
                  LAST STATUS
                </td>
                <td style="font-weight: bold !important;">
                  NUMBER
                </td>
                <td style="font-weight: bold !important;">REV</td>
                <td style="font-weight: bold !important;">TITLE</td>
              </tr>
              <template v-for="(item, i) in transmittalDetail.design">
                <tr :key="i">
                  <td width="5%">{{ i + 1 }}</td>
                  <td width="10%">
                    {{ item.design ? item.design.last_status_code : "-" }}
                  </td>
                  <td width="20%">
                    {{ item.design ? item.design.numbering : "-" }}
                  </td>
                  <td width="5%">
                    {{ item.design ? item.design.revision : "-" }}
                  </td>
                  <td width="60%">
                    {{ item.design ? item.design.title : "-" }}
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </section>
      </VueHtml2pdf>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_REPORT_TRANSMITTAL_BY_ID } from "@/core/services/store/report-transmittal.module";
import { mapGetters } from "vuex";
import { showToast } from "../../../../core/helper/toast.helper";
import ApiService from "../../../../core/services/api.service";
// import CompleteTable from "../../../content/widgets/advance-table/CompleteTable";

// import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "transmittal-detail",
  components: {
    // VueHtml2pdf
    // CompleteTable,
  },
  data() {
    return {
      pdfDialog: false,
      pdfLoading: true,
      pdfList: [],
      filter: {
        project_code: null,
        project_nickname: null,
        transmittal_number: null,
        company_name: null,
      },
      currentItem: null,
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" },
        ],
      },
      data: {
        projects: [
          {
            id: 1,
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN",
            status: "Open",
            owner: "BALAI TEKNIK PERKERETAAPIAN",
          },
        ],
      },
      loadingActive: false,
      tableMainProject: true,
    };
  },
  methods: {
    toTransmittalDetail(data) {
      this.$router.push({
        name: "transmittal-list",
        params: {
          id: data.code,
        },
      });
    },

    onGetTransmittalDetail(id) {
      this.$store.dispatch(GET_REPORT_TRANSMITTAL_BY_ID, id);
    },

    exportExcel(divId, fileName) {
      console.log(divId, fileName);

      let uri = "data:application/vnd.ms-excel;base64,",
        template =
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">' +
          '<head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>' +
          "</head>" +
          "<body><table>{table}</table></body></html>",
        base64 = function(s) {
          return window.btoa(unescape(encodeURIComponent(s)));
        },
        format = function(s, c) {
          return s.replace(/{(\w+)}/g, function(m, p) {
            return c[p];
          });
        };

      let table = null;

      table = document.getElementById(divId);

      const ctx = {
        worksheet: "reporting-transmittal" || "Worksheet",
        // eslint-disable-next-line prettier/prettier
        table: table.innerHTML,
      };

      const link = document.createElement("a");

      link.setAttribute("href", uri + base64(format(template, ctx)));

      link.setAttribute("download", fileName + ".xls");

      link.click();
    },

    exportPDF() {
      // window.URL.createObjectURL(
      //   process.env.VUE_APP_BASE_URL +
      //     `/project/transmittal/${this.$route.params.id}/pdf`,
      //   "_blank",
      //   "noreferrer"
      // );
      var fileUrl = "";
      ApiService.setHeader();

      this.loading = true;

      ApiService.post("project/transmittal/pdf", {
        id: this.$route.params.id,
      }).then((response) => {
        console.log("ini", response);
        fileUrl = response.data.data;
        ApiService.postDownload("ftp/download", {
          file_url: fileUrl,
        })
          .then((responseDownload) => {
            const fileURL = window.URL.createObjectURL(
              new Blob([responseDownload?.data])
            );

            const fileLink = document.createElement("a");

            fileLink.href = fileURL;

            const pathArray = fileUrl.split("/");
            var fileName = pathArray.at(-1);

            fileLink.setAttribute("download", fileName);

            document.body.appendChild(fileLink);

            fileLink.click();

            this.loading = false;
          })
          .catch(() => {
            showToast("Failed", "Download failed, try again", "danger");
          });
      });

      // this.$refs.html2Pdf.generatePdf();
    },
  },
  async created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Transmittal Detail" }]);
    this.onGetTransmittalDetail(this.$route.params.id);

    this.loadingActive = true;
    this.tableMainProject = false;
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentMenus",
      "currentReportTransmittalDetail",
      "currentLoadingTransmittalReport",
      "layoutConfig",
    ]),

    loading() {
      return this.currentLoadingTransmittalReport;
    },

    transmittalDetail() {
      return this.currentReportTransmittalDetail;
    },

    logo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
};
</script>

<style>
.pull-right {
  float: right;
}
</style>
